import * as React from "react"
import { Helmet } from 'react-helmet'
import Nav from '../components/nav'
import Banner from '../components/banner'
import favicon from '../images/favicon.png'

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>eurorackDIY Home</title>
        <link rel="icon" href={favicon} />
      </Helmet>

      <Banner />
      <Nav />

      <main class="container mx-auto text-center">
        <div class="pt-4 md:p-10">
          <h1 class="items-center text-xl md:text-4xl text-ediy">
            Instructional videos showing how to design and create Eurorack synthesizer modules from the ground up.
          </h1>
        </div>
      </main>
    </>
  )
}

export default IndexPage
